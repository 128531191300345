import "./App.css";
import {
  HashRouter,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import Product from "./pages/Product/Product";
import Homepage from "./pages/Homepage/Homepage";
import Auth from "./pages/Auth/Auth";
import ProductDetails from "./pages/productDetails/ProductDetails";
import Customer from "./pages/Customers/Customers";
import Base from "./component/Base/Base";
import Cart from "./pages/Cart/Cart";
import Order from "./pages/Order/Order";
import CustomerDetails from "./pages/CustomerDetails/CustomerDetails";
import ProductProvider from "./pages/Product/ProductProvier";
import { RoutePublic } from "./RoutePublic";
function App() {
  return (
    <Routes>
      {RoutePublic.map((item, index) => (
        <Route path={item.path} element={<Base>{item.element}</Base>}></Route>
      ))}

      <Route path="/login" element={<Auth check="login"></Auth>}></Route>
    </Routes>
  );
}

export default App;
