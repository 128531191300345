import { React, useState } from "react";
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import BACKGROUNDLOGO from "../../assets/image/logo1.png";
import {
  FormBase,
  FooterBase,
  ButtonSignIn,
  Container,
  AppContainer,
} from "./Component/Component";
import styled from "styled-components";
import { Theme } from "../../theme";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { HandleLogin } from "../../services/handleAccount/handleAccount";

import { LoginF } from "./LoginForm";
import { RegisterF } from "./RegisterForm";
import { CheckF } from "./CheckForm";
const AccountBox = ({ check }) => {
  let nav = useNavigate();
  const [stateCheck, setStateCheck] = useState("check");
  const [showPassword, setShowPassword] = useState(false);
  const [FormLogin, setFormLogin] = useState({
    phone: "",
    password: "",
  });
  const [isLoading, setIsloading] = useState(false);
  const [DataError, setDataError] = useState({});
  const MutationLogin = useMutation({
    mutationFn: (body) => {
      return HandleLogin(body);
    },
  });
  const onChange = (e) => {
    setFormLogin({
      ...FormLogin,
      [e.target.name]: e.target.value,
    });
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const sLogin = () => {
    setIsloading(true);
    MutationLogin.mutate(FormLogin, {
      onSuccess: (data) => {
        if (data?.errors) {
          setDataError(data);
          setIsloading(false);
        } else {
          localStorage.setItem("token", data.data.data.access_token);
          nav("/product");
        }
      },
      onError: (err) => {
        console.log("lỗi " + err);
        setIsloading(false);
      },
    });
  };

  return (
    <>
      <Box
        width={"100%"}
        gap={5}
        justifyContent={"space-around"}
        className="row"
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          className="col-xs-12 col-md-4"
          alignItems={"flex-start"}
        >
          <Typography variant="h2" fontWeight={700} color={"#262626"}>
            Xin chào
          </Typography>

          <Typography
            fontSize={Theme.designColors.SUBTITLESIZE2}
            fontWeight={600}
            color={"#262626"}
          >
            Nền tảng thương mại điện tử cho sản phẩm tư vấn
          </Typography>
        </Box>
        {stateCheck == "login" ? (
          <LoginF
            FormLogin={FormLogin}
            setFormLogin={setFormLogin}
            setStateCheck={setStateCheck}
          ></LoginF>
        ) : stateCheck == "register" ? (
          <RegisterF
            FormLogin={FormLogin}
            setFormLogin={setFormLogin}
            setStateCheck={setStateCheck}
          ></RegisterF>
        ) : (
          <CheckF
            FormLogin={FormLogin}
            setFormLogin={setFormLogin}
            setStateCheck={setStateCheck}
            isLoading={isLoading}
            DataError={DataError}
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            nav={nav}
          ></CheckF>
        )}
      </Box>
    </>
  );
};

export default AccountBox;
