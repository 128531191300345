// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .popup-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 30%;
  }
  
  .close-button {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close-button:hover,
  .close-button:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    .popup-content {
      width: 80%;
      margin: 20% auto;
    }
  }
  
  @media (max-width: 480px) {
    .popup-content {
      width: 90%;
      margin: 30% auto;
    }
  }
  .cssforLeftdiscount {
    background-color: #ff6b6b;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    /* position: absolute; */
    top: 10px;
    left: 10px;
    clip-path: polygon(0 50%, 100% 0, 100% 100%);
  }`, "",{"version":3,"sources":["webpack://./src/assets/css/styles.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,UAAU;IACV,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;IACZ,cAAc;IACd,oCAAoC;EACtC;;EAEA;IACE,yBAAyB;IACzB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,iBAAiB;EACnB;;EAEA;;IAEE,YAAY;IACZ,qBAAqB;IACrB,eAAe;EACjB;EACA;IACE;MACE,UAAU;MACV,gBAAgB;IAClB;EACF;;EAEA;IACE;MACE,UAAU;MACV,gBAAgB;IAClB;EACF;EACA;IACE,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,wBAAwB;IACxB,SAAS;IACT,UAAU;IACV,4CAA4C;EAC9C","sourcesContent":[".popup {\r\n    display: block;\r\n    position: fixed;\r\n    z-index: 1;\r\n    left: 0;\r\n    top: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    overflow: auto;\r\n    background-color: rgba(0, 0, 0, 0.4);\r\n  }\r\n  \r\n  .popup-content {\r\n    background-color: #fefefe;\r\n    margin: 15% auto;\r\n    padding: 20px;\r\n    border: 1px solid #888;\r\n    width: 30%;\r\n  }\r\n  \r\n  .close-button {\r\n    color: #aaa;\r\n    float: right;\r\n    font-size: 28px;\r\n    font-weight: bold;\r\n  }\r\n  \r\n  .close-button:hover,\r\n  .close-button:focus {\r\n    color: black;\r\n    text-decoration: none;\r\n    cursor: pointer;\r\n  }\r\n  @media (max-width: 768px) {\r\n    .popup-content {\r\n      width: 80%;\r\n      margin: 20% auto;\r\n    }\r\n  }\r\n  \r\n  @media (max-width: 480px) {\r\n    .popup-content {\r\n      width: 90%;\r\n      margin: 30% auto;\r\n    }\r\n  }\r\n  .cssforLeftdiscount {\r\n    background-color: #ff6b6b;\r\n    color: #fff;\r\n    font-size: 14px;\r\n    font-weight: bold;\r\n    padding: 5px 10px;\r\n    /* position: absolute; */\r\n    top: 10px;\r\n    left: 10px;\r\n    clip-path: polygon(0 50%, 100% 0, 100% 100%);\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
