import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import FormControlLabel from "@mui/material/FormControlLabel";
import IMGTHANHTOANCHUYENHANG from "../../assets/logo/chuyển khoản ngân hàng.png";
import IMGTHANHTOANNHANHANG from "../../assets/logo/thanh toán khi nhận hàng.png";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { mockdataProduct } from "../../data/data";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import { NotificationsCustom } from "../../component/Notifications";
import { Store } from "react-notifications-component";
import { Theme } from "../../theme";
import ErrorIcon from "@mui/icons-material/Error";
import "./style.css";
import {
  Breadcrumbs,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  GET_CART,
  CREATE_ORDER,
  REMOVE_ITEM_CART,
} from "../../services/handlePurchase/handlePurchase";
import { useMutation, useQuery } from "@tanstack/react-query";

const CartMB = ({ stateCustomer, stateCart, TriggerRefreshCart }) => {
  let nav = useNavigate();
  const [stateOption, setStateOption] = React.useState({
    NBCP: "669fc269b373e28b740eecf0",
    KHCP: "669fc27cb373e28b740eecf1",
    TTNH: "669fbf6c5e23cda7dbdf2474",
    TTCK: "669fbf8a5e23cda7dbdf2475",
  });
  const [selectMethodBuy, setSelectMethodBuy] = React.useState({
    id: stateOption.TTNH,
    name: "Thanh toán khi nhận hàng",
  });
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [selectProduct, setSelectProduct] = React.useState([]);
  const [stateProduct, setStateProduct] = React.useState([]);
  const [stateErrors, setStateErrors] = React.useState({});
  const [Isloading, setIsloading] = React.useState(false);

  const [error, setError] = React.useState({
    handleErrorChoose: false,
    handleErrorProduct: false,
  });
  const [checked, setChecked] = React.useState(
    stateCart[0]?.purchase_items.map(() => true) || []
  );
  const [stateCountTotal, setStateCountTotal] = React.useState({
    TotalProduct: 0,
    TotalMoney: 0,
  });

  const CUSTOMER_CREATE_ORDER = useMutation({
    mutationFn: (body) => {
      return CREATE_ORDER(body);
    },
  });
  const Handle_Remove_Item_cart = useMutation({
    mutationFn: (body) => {
      return REMOVE_ITEM_CART(body);
    },
  });
  const handleRemoveItem = (id) => {
    let FormSend = {
      purchase_items: [
        {
          product_id: id,
        },
      ],
    };

    Handle_Remove_Item_cart.mutate(FormSend, {
      onSuccess: (data) => {
        DATA_GET_CART.refetch();
        TriggerRefreshCart();
      },
      onError: (err) => {
        console.log("err " + err);
      },
    });
  };
  const DATA_GET_CART = useQuery({
    queryKey: ["GET_CART"],
    queryFn: async () => {
      const result = await GET_CART();
      if (result?.data?.data) {
        setStateProduct(result?.data?.data[0]?.purchase_items);
        setSelectProduct(result?.data?.data[0]?.purchase_items);
        setChecked(result?.data?.data[0]?.purchase_items.map(() => true));
        setStateCountTotal({
          ...stateCountTotal,
          TotalMoney: result?.data?.data[0]?.purchase_items.reduce(
            (accumulator, currentItem) => {
              return (
                accumulator +
                currentItem?.product_price_for_customer *
                  parseInt(currentItem?.buy_count)
              );
            },
            0
          ),
          TotalProduct: result?.data?.data[0]?.purchase_items.length || 0,
        });
      }
      return result;
    },
  });

  const handleChange = (event, index) => {
    //Chọn vật phẩm
    let updatedChecked;
    if (index === -1) {
      const allChecked = event.target.checked;
      updatedChecked = checked.map(() => allChecked);
    } else {
      updatedChecked = [...checked];
      updatedChecked[index] = event.target.checked;
    }
    setChecked(updatedChecked);

    const selectedItems = stateProduct.filter((_, i) => updatedChecked[i]);
    setSelectProduct(selectedItems);
    setStateCountTotal({
      ...stateCountTotal,
      TotalMoney: selectedItems.reduce((accumulator, currentItem) => {
        return (
          accumulator +
          currentItem?.product_price_for_customer *
            parseInt(currentItem?.buy_count)
        );
      }, 0),
      TotalProduct: selectedItems.length,
    });
    setError({ ...error, handleErrorProduct: false });
  };

  const updateBuyCount = (id, buyCount) => {
    const update = stateProduct.map((obj) => {
      if (obj.product_id === id) {
        // Kiểm tra nếu buy_count >= 1
        if (obj.buy_count + buyCount < 1) {
          alert("Số lượng mua phải từ 1");
        } else {
          if (obj.buy_count + buyCount > obj.initial_stock) {
            alert("Số lượng mua đã vượt quá kho");
          } else {
            return { ...obj, buy_count: obj.buy_count + buyCount };
          }
        }
        // Cập nhật buy_count
      }
      return obj;
    });

    let updatedChecked;

    updatedChecked = [...checked];
    const selectedItems = update.filter((_, i) => updatedChecked[i]);
    setSelectProduct(selectedItems);

    setStateProduct(update);

    setError({ ...error, handleErrorProduct: false });
  };

  // Gọi hàm này khi cần thay đổi view
  const handleChangeBuyCount = (id, buyCount) => {
    updateBuyCount(id, buyCount);
  };

  const handleChangeBuyCountInput = (id, buyCount) => {
    let insert = buyCount < 1 ? 1 : buyCount;
    const update = stateProduct.map((obj) =>
      obj.product_id === id ? { ...obj, buy_count: insert } : obj
    );

    let updatedChecked;

    updatedChecked = [...checked];
    const selectedItems = update.filter((_, i) => updatedChecked[i]);
    setSelectProduct(selectedItems);
    setStateProduct(update);
    setStateCountTotal({
      ...stateCountTotal,
      TotalMoney: selectedItems.reduce((accumulator, currentItem) => {
        return (
          accumulator +
          currentItem?.product_price_for_customer *
            parseInt(currentItem?.buy_count)
        );
      }, 0),
      TotalProduct: selectedItems.length,
    });
    setError({ ...error, handleErrorProduct: false });
  };

  const ComponentIteam = ({ stateProduct }) => {
    return (
      <ListItem>
        <Box
          display={"flex"}
          className="add_flex_decoration_mb"
          gap={3}
          alignItems="center"
        >
          <ListItemAvatar>
            <img
              src={stateProduct?.product_images[0]?.path}
              width={200}
              height={200}
            ></img>
          </ListItemAvatar>
          <ListItemText
            sx={
              {
                // minWidth: "180px",
                // width: "180px",
              }
            }
            primary={
              <>
                <Typography
                  color={`${
                    stateErrors?.errors &&
                    stateErrors?.errors.includes(stateProduct?.product_id) ===
                      true
                      ? "red"
                      : ""
                  }`}
                >
                  {stateProduct?.name}
                </Typography>
                {stateErrors?.errors &&
                stateErrors?.errors.includes(stateProduct?.product_id) ===
                  true ? (
                  <Typography fontSize={"12px"} color={"red"}>
                    *
                    {stateErrors?.message === "Out of stock"
                      ? "Số lượng đơn hàng đã vượt quá kho"
                      : "Sản phẩm này đã ngưng hoạt động"}
                  </Typography>
                ) : (
                  ""
                )}
              </>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  color={Theme.designColors.Text}
                >
                  Số tiền :{" "}
                  {parseInt(
                    stateProduct?.product_price_for_customer
                  ).toLocaleString("en-us")}
                </Typography>
                <div className="flex-auto">
                  <Typography
                    htmlFor="horizontal-buttons"
                    className="font-bold block mb-2"
                    color={Theme.designColors.Text}
                  >
                    Số lượng : {stateProduct?.initial_stock}
                  </Typography>
                </div>
                <Box display={"flex"}>
                  <div className="container">
                    <button
                      onClick={() => {
                        handleChangeBuyCount(stateProduct?.product_id, -1);
                      }}
                      className="button"
                    >
                      -
                    </button>

                    <input
                      className="input"
                      defaultValue={stateProduct?.buy_count}
                      type="number"
                      min={1}
                      max={stateProduct?.initial_stock}
                      onBlur={(e) => {
                        if (
                          parseInt(e.target.value) <=
                            stateProduct?.initial_stock &&
                          parseInt(e.target.value) >= 0
                        ) {
                          handleChangeBuyCountInput(
                            stateProduct?.product_id,
                            parseInt(e.target.value)
                          );
                        } else {
                          handleChangeBuyCountInput(
                            stateProduct?.product_id,
                            parseInt(e.target.defaultValue)
                          );
                        }
                      }}
                    />

                    <button
                      onClick={() => {
                        handleChangeBuyCount(stateProduct?.product_id, 1);
                      }}
                      className="button"
                    >
                      +
                    </button>
                  </div>
                </Box>
              </React.Fragment>
            }
          />
        </Box>
      </ListItem>
    );
  };

  const SpanLable = ({ content, fontSize, fontWeight }) => {
    return (
      <Typography
        fontWeight={fontWeight || 500}
        color={Theme.designColors.Text}
        fontSize={fontSize || ".9rem"}
      >
        {content}
      </Typography>
    );
  };

  const handleClickFail = () => {
    setIsloading(true);

    if (selectProduct.length === 0) {
      setError({ ...error, handleErrorProduct: true });

      setIsloading(false);
      return;
    }

    if (selectMethodBuy?.id && selectProduct.length !== 0) {
      let FORM = {
        purchase_items: selectProduct,

        payment_method_id: selectMethodBuy?.id,

        cost_bearer_id: "669fc27cb373e28b740eecf1",
      };

      CUSTOMER_CREATE_ORDER.mutate(FORM, {
        onSuccess: (data) => {
          if (data?.data) {
            setIsloading(false);

            setSelectMethodBuy({
              id: stateOption.TTNH,
              name: "Thanh toán khi nhận hàng",
            });

            setStateErrors({});
            setChecked(stateProduct.map(() => false));
            setStateCountTotal({
              TotalProduct: 0,
              TotalMoney: 0,
            });
            const processItemsSequentially = async () => {
              for (const item of selectProduct) {
                const FormSend = {
                  purchase_items: [
                    {
                      product_id: item?.product_id,
                    },
                  ],
                };

                try {
                  await new Promise((resolve, reject) => {
                    Handle_Remove_Item_cart.mutate(FormSend, {
                      onSuccess: resolve,
                      onError: reject,
                    });
                  });
                } catch (error) {
                  console.log("Error: ", error);
                  return; // Dừng lại nếu có lỗi
                }
              }

              // Sau khi hoàn thành tất cả các thao tác

              DATA_GET_CART.refetch();
              TriggerRefreshCart();
            };

            processItemsSequentially();

            Store.addNotification({
              ...NotificationsCustom({
                title: "Thành công",
                message: "Lên đơn thành công, vui lòng kiểm tra mục đơn hàng",
                type: "success",
              }),
            });
          } else {
            Store.addNotification({
              ...NotificationsCustom({
                title: "Lỗi",
                message: "Vui lòng thử lại sau",
                type: "danger",
              }),
            });
            setStateErrors(data);

            setIsloading(false);
          }
        },
        onError: (err) => {
          alert("Lỗi, vui lòng thử lại sau");
          console.log("err " + err);
          setIsloading(false);
        },
      });
    }
  };

  return (
    <>
      <Box
        mt={3}
        className="container"
        display={"flex"}
        flexDirection={"column"}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ width: "100%" }}
        >
          <Typography
            sx={{ cursor: "pointer" }}
            onClick={() => {
              nav("/");
            }}
          >
            Trang chủ
          </Typography>
          <Typography sx={{ color: Theme.designColors.PrimaryBrand5 }}>
            Giỏ hàng
          </Typography>
        </Breadcrumbs>
        {stateProduct && stateProduct.length > 0 ? (
          <Box width={"100%"} mt={3} className="row">
            <Box className="col-md-8" p={0}>
              <Box height={"auto"} width={"100%"}>
                <Box>
                  <FormControlLabel
                    sx={{
                      justifyContent: "flex-start",
                      borderRadius: "10px",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                      width: "100%",
                      bgcolor: "#f3f3f3",
                      margin: "0px",
                    }}
                    label={
                      <SpanLable
                        fontWeight={600}
                        color={"#1F1F1F"}
                        fontSize={"20px"}
                        content={"Chọn tất cả sản phẩm"}
                      ></SpanLable>
                    }
                    control={
                      <Checkbox
                        checked={
                          Array.isArray(checked) && checked.length > 0
                            ? checked.every(Boolean)
                            : false
                        }
                        indeterminate={
                          Array.isArray(checked) && checked.length > 0
                            ? checked.some(Boolean) && !checked.every(Boolean)
                            : false
                        }
                        onChange={(event) => handleChange(event, -1)}
                      />
                    }
                  />
                  {error.handleErrorProduct ? (
                    <Typography
                      display={"flex"}
                      alignItems={"center"}
                      color={"error"}
                    >
                      <ErrorIcon></ErrorIcon>
                      Vui lòng chọn sản phẩm
                    </Typography>
                  ) : (
                    ""
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      height: "70vh",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      flexDirection: "column",
                      ml: 3,
                    }}
                  >
                    {stateProduct &&
                      stateProduct.map((item, index) => (
                        <Box position={"relative"}>
                          <FormControlLabel
                            style={{
                              marginBottom: "20px",
                              position: "relative",
                            }}
                            label={
                              <ComponentIteam
                                stateProduct={item}
                              ></ComponentIteam>
                            }
                            control={
                              <Checkbox
                                checked={checked[index]}
                                onChange={(event) => handleChange(event, index)}
                              />
                            }
                          ></FormControlLabel>
                          <Box
                            sx={{
                              position: "absolute",
                              top: "50%",
                              right: "10%",
                              transform: " translate(50%, 0)",
                            }}
                            display={"flex"}
                          >
                            <Button
                              color="error"
                              style={{ margin: "auto", marginRight: "20px" }}
                              onClick={() => {
                                handleRemoveItem(item?.product_id);
                              }}
                            >
                              <DeleteForeverIcon></DeleteForeverIcon>
                            </Button>
                          </Box>
                        </Box>
                      ))}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="col-md-4">
              <Box
                bgcolor={"#f3f3f3"}
                padding={3}
                height={"auto"}
                display={"flex"}
                flexDirection={"column"}
                borderRadius={"20px"}
                boxShadow={"0 2px 5px rgba(0, 0, 0, 0.2)"}
                gap={3}
              >
                <Typography>Tổng thanh toán</Typography>
                <FormControl
                  fullWidth
                  sx={{
                    padding: "16px",
                  }}
                >
                  <FormLabel
                    fontWeight="500"
                    id="demo-radio-buttons-group-label"
                    sx={{
                      color: "black",
                      fontWeight: "600",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <PaymentOutlinedIcon
                      sx={{ fontSize: "1.4rem" }}
                    ></PaymentOutlinedIcon>
                    <Typography
                      ml={1}
                      fontWeight={600}
                      color={"#1F1F1F"}
                      fontSize={Theme.designColors.SUBTITLESIZE}
                    >
                      {" "}
                      Phương thức thanh toán
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    style={{ paddingBottom: "12px" }}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="NH"
                    name="radio-buttons-group"
                    onChange={(e) => {
                      if (e.target.value === "NH") {
                        setSelectMethodBuy({
                          id: stateOption.TTNH,
                          name: "Thanh toán khi nhận hàng",
                        });
                      } else {
                        setSelectMethodBuy({
                          id: stateOption.TTCK,
                          name: "Thanh toán chuyển khoản",
                        });
                      }
                    }}
                  >
                    <FormControlLabel
                      labelPlacement="start"
                      sx={{ justifyContent: "space-between", margin: "0px" }}
                      value="NH"
                      control={<Radio />}
                      label={
                        <SpanLable
                          icon={IMGTHANHTOANNHANHANG}
                          fontSize={Theme.designColors.SUBTITLESIZE2}
                          fontWeight={"600"}
                          content={`Thanh toán khi nhận hàng`}
                        ></SpanLable>
                      }
                    />
                    <hr style={{ color: "#5b6265", width: "100%" }}></hr>
                    <FormControlLabel
                      labelPlacement="start"
                      sx={{ justifyContent: "space-between", margin: "0px" }}
                      value="online"
                      control={<Radio />}
                      label={
                        <SpanLable
                          fontSize={Theme.designColors.SUBTITLESIZE2}
                          fontWeight={"600"}
                          icon={IMGTHANHTOANCHUYENHANG}
                          content={"Thanh toán bằng chuyển khoản"}
                        ></SpanLable>
                      }
                    />
                  </RadioGroup>
                </FormControl>
                <Typography
                  fontSize={Theme.designColors.SUBTITLESIZE2}
                  color={"GrayText"}
                >
                  *Phí vận chuyển sẽ tính thêm 30.000đ, đối với các đơn hàng trị
                  giá từ 2.000.000đ sẽ được miễn phí vận chuyển
                </Typography>
                <Box display={"flex"} className="row">
                  <Typography className="col-4" fontSize={"12px"}>
                    {stateCountTotal?.TotalProduct} sản phẩm
                  </Typography>
                  <Typography
                    className="col-8"
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    {/* <Typography
                    color={"green"}
                    fontSize={Theme.designColors.SUBTITLESIZE2}
                  >
                    Phí vận chuyển :{" "}
                    {selectProduct?.length > 0 &&
                    parseInt(stateCountTotal?.TotalMoney) >= 2000000
                      ? 0
                      : "+ 30.000đ"}
                  </Typography> */}
                    <Typography
                      fontSize={"24px"}
                      color={Theme.designColors.PrimaryBrand5}
                    >
                      {" "}
                      Số tiền :{" "}
                      {parseInt(stateCountTotal?.TotalMoney).toLocaleString(
                        "en-us"
                      )}
                    </Typography>
                  </Typography>
                </Box>

                <Button
                  sx={{
                    color: Theme.designColors.PrimaryBrand5,
                    borderColor: Theme.designColors.PrimaryBrand5,
                  }}
                  variant="outlined"
                  onClick={() => {
                    handleClickFail();
                  }}
                >
                  XÁC NHẬN LÊN ĐƠN
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Typography>Chưa có sản phẩm nào</Typography>
        )}
      </Box>
    </>
  );
};

export default CartMB;
