import PopupNotice from "../../component/PopupNotice/PopupNotice";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  Pagination,
  Rating,
  Skeleton,
} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import ICONTUVAN from "../../assets/Icon Tikpii/Vector.svg";
import ICONHCH from "../../assets/Icon Tikpii/Vector (1).svg";
import ICONTRALAI from "../../assets/Icon Tikpii/Vector (2).svg";
import IMGEVENT from "../../assets/image/Rectangle 349.png";

import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { CssTransition } from "@mui/base/Transitions";
import { PopupContext } from "@mui/base/Unstable_Popup";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Avatar from "@mui/material/Avatar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect } from "react";
import { MockDataIMG as dataIMG, MockDataCateGories } from "../../data/data";
import { TitleProduct } from "../../component/Styles/Title";
import SpaIcon from "@mui/icons-material/Spa";
import {
  Listbox,
  MenuButton,
  MenuItem,
  ButtonMoveLeft,
  ButtonMoveRight,
} from "./Components/Components";
import { styled } from "@mui/material/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Theme } from "../../theme";
import { Carousel } from "react-responsive-carousel";
import { MockDataIMGTWO, mockdataProduct } from "../../data/data";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { MediaCard } from "../../component/component";
import { useNavigate } from "react-router-dom";
import Footer from "../../component/Footer/footer";

const Product = ({
  stateProduct,
  Isloading,
  stateLimit,
  setStateLimit,
  setStatePage,
  statePage,
  stateCount,
  stateProvider,
  stateCategory,

  isloadingCategory,
  isloadingProvider,
}) => {
  let nav = useNavigate();
  const IMG1 =
    "https://res.cloudinary.com/dhscgjuis/image/upload/v1726200910/TikpiiMall/Blog/mpcvs7jlcbds96nabown.svg";
  const IMG2 =
    "https://res.cloudinary.com/dhscgjuis/image/upload/v1726200909/TikpiiMall/Blog/szw5kgxtzfrw742c8yud.svg";
  const IMG3 =
    "https://res.cloudinary.com/dhscgjuis/image/upload/v1726200909/TikpiiMall/Blog/ygfstqikq3cazsxmhd6b.svg";

  const scrollContainerRef = React.useRef(null);
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#ff6d75",
    },
    "& .MuiRating-iconHover": {
      color: "#ff3d47",
    },
  });
  const handleChange = (event, value) => {
    setStatePage(value);
  };
  useEffect(() => {
    if (scrollContainerRef.current) {
      const scrollWidth = scrollContainerRef.current.scrollWidth;
      const clientWidth = scrollContainerRef.current.clientWidth;
      scrollContainerRef.current.scrollLeft = (scrollWidth - clientWidth) / 2;
    }
  }, []);

  const scrollLeft = (event) => {
    event.preventDefault(); // Ngăn chặn sự kiện mặc định của nút
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -500,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = (event) => {
    event.preventDefault(); // Ngăn chặn sự kiện mặc định của nút
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 500,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const AnimatedListbox = React.forwardRef(function AnimatedListbox(
    props,
    ref
  ) {
    const { ownerState, ...other } = props;
    const popupContext = React.useContext(PopupContext);

    if (popupContext == null) {
      throw new Error(
        "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
      );
    }

    const verticalPlacement = popupContext.placement.split("-")[0];

    return (
      <CssTransition
        className={`placement-${verticalPlacement}`}
        enterClassName="open"
        exitClassName="closed"
      >
        <Listbox {...other} ref={ref} />
      </CssTransition>
    );
  });

  AnimatedListbox.propTypes = {
    ownerState: PropTypes.object.isRequired,
  };

  return (
    <>
      <Box
        mt={3}
        position="relative"
        bgcolor={Theme.BackGround.gray}
        height="auto"
        display={"flex"}
        sx={{ overflowX: "hidden" }}
        ref={scrollContainerRef}
        className="container"
      >
        <ButtonMoveLeft onClick={scrollLeft}>
          <ArrowBackIosIcon></ArrowBackIosIcon>
        </ButtonMoveLeft>

        <Box position="relative">
          <Box mt={1} display="flex" gap="20px">
            {isloadingCategory ? (
              <>
                {" "}
                {[...Array(5)].map((_, index) => (
                  <Skeleton
                    variant="rectangular"
                    sx={{ borderRadius: "20px" }}
                    width={210}
                    height={50}
                  />
                ))}
              </>
            ) : (
              <>
                {" "}
                {stateCategory &&
                  stateCategory
                    .slice(0, Math.floor(stateCategory.length / 2))

                    .map((_, index) => (
                      <Typography
                        key={index} // Add key to each item in the map
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color={_?.color}
                        marginBottom={"10px"}
                        gap="10px"
                        borderRadius="15px"
                        padding="10px 25px"
                        bgcolor="white"
                        boxShadow={"0 8px 5px rgba(0, 0, 0, 0.2)"}
                        fontSize={Theme?.designColors.SUBTITLESIZE}
                        fontWeight="600"
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          nav(`/pCategory/${_?._id}`, { state: _ });
                        }}
                      >
                        <img width={25} height={25} src={_?.image}></img>

                        {_?.name}
                      </Typography>
                    ))}
              </>
            )}
          </Box>
          <Box mt={1} display="flex" gap="20px">
            {isloadingCategory ? (
              <>
                {" "}
                {[...Array(5)].map((_, index) => (
                  <Skeleton
                    variant="rectangular"
                    sx={{ borderRadius: "20px" }}
                    width={210}
                    height={50}
                  />
                ))}
              </>
            ) : (
              <>
                {" "}
                {stateCategory &&
                  stateCategory
                    .slice(
                      Math.floor(stateCategory.length / 2) + 1,
                      Math.floor(stateCategory.length)
                    )
                    .map((_, index) => (
                      <Typography
                        key={index} // Add key to each item in the map
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color={_?.color}
                        marginBottom={"10px"}
                        gap="10px"
                        borderRadius="15px"
                        padding="10px 25px"
                        bgcolor="white"
                        boxShadow={"0 8px 5px rgba(0, 0, 0, 0.2)"}
                        fontSize={Theme?.designColors.SUBTITLESIZE}
                        fontWeight="600"
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          nav(`/pCategory/${_?._id}`, { state: _ });
                        }}
                      >
                        <img width={25} height={25} src={_?.image}></img>

                        {_?.name}
                      </Typography>
                    ))}
              </>
            )}
          </Box>
        </Box>
        <ButtonMoveRight onClick={scrollRight}>
          <ArrowForwardIosIcon></ArrowForwardIosIcon>
        </ButtonMoveRight>
      </Box>
      <Box>
        <Carousel
          autoPlay={true}
          interval={3000}
          infiniteLoop={true}
          showStatus={false}
          showThumbs={false}
        >
          {MockDataIMGTWO.map((item, index) => (
            <Box p={5}>
              <img
                style={{
                  height: "auto",
                  width: "100%",
                  borderRadius: "20px",
                }}
                src={item}
              />
              {/* <p className="legend">sản phẩm thứ {index}</p> */}
            </Box>
          ))}
        </Carousel>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "35px 0px",
          width: "100%",
          marginLeft: "5px",
          background: `linear-gradient(to right,  ${Theme.designColors.PrimaryBrand3} 60%, ${Theme.designColors.SecondaryBrand2}) 50%`,
        }}
        height={"auto"}
        className="row"
      >
        <Box className="col-xs-12 col-md-4" display={"flex"}>
          <Box
            margin={"auto"}
            width={"300px"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={1}
          >
            <img src={ICONTUVAN} width={45} height={45}></img>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Typography
                sx={{
                  fontSize: Theme.designColors.TITLESIZE,
                  color: Theme.designColors.Text,
                }}
              >
                {" "}
                Tư vấn & chăm sóc
              </Typography>

              <Typography
                sx={{
                  fontSize: Theme.designColors.SUBTITLESIZE,
                  color: Theme.designColors.SubtleText,
                }}
              >
                {" "}
                Tư vấn & chăm sóc
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="col-xs-12 col-md-4" display={"flex"}>
          <Box
            margin={"auto"}
            width={"300px"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={1}
          >
            <img src={ICONHCH} width={45} height={45}></img>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Typography
                sx={{
                  fontSize: Theme.designColors.TITLESIZE,
                  color: Theme.designColors.Text,
                }}
              >
                {" "}
                hàng chính hãng
              </Typography>

              <Typography
                sx={{
                  fontSize: Theme.designColors.SUBTITLESIZE,
                  color: Theme.designColors.SubtleText,
                }}
              >
                {" "}
                Cam kết đảm bảo nguồn gốc
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="col-xs-12 col-md-4" display={"flex"}>
          <Box
            margin={"auto"}
            width={"300px"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={1}
          >
            <img src={ICONTRALAI} width={45} height={45}></img>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Typography
                sx={{
                  fontSize: Theme.designColors.TITLESIZE,
                  color: Theme.designColors.Text,
                }}
              >
                {" "}
                Cho phép trả hàng
              </Typography>

              <Typography
                sx={{
                  fontSize: Theme.designColors.SUBTITLESIZE,
                  color: Theme.designColors.SubtleText,
                }}
              >
                {" "}
                Đổi trả trong vòng 15 ngày
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={3} display={"flex"} justifyContent={"space-between"}>
        <Typography
          color={Theme.color.title}
          fontSize={Theme.designColors.TITLESIZE}
          fontWeight={600}
        >
          SẢN PHẨM BÁN CHẠY
        </Typography>
      </Box>
      <div
        style={{ overflowX: "scroll", marginTop: "5px", paddingBottom: "10px" }}
        className="container col-12"
      >
        <ButtonGroup
          style={{ gap: "1rem" }}
          variant="outlined"
          aria-label="Basic button group"
        >
          {stateProvider &&
            stateProvider.map((item, index) => (
              <Dropdown>
                <MenuButton
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="cssforButtonItem"
                  onClick={() => {
                    nav(`/pProvider/${item?._id}`);
                  }}
                >
                  <img src={item?.avatar} width={20} height={20}></img>
                  <Typography
                    fontSize={Theme.designColors.SUBTITLESIZE2}
                    fontWeight={700}
                    ml={1}
                    mr={3}
                  >
                    {" "}
                    {item?.name}
                  </Typography>
                </MenuButton>
              </Dropdown>
            ))}
        </ButtonGroup>
      </div>

      <Box
        mt={3}
        style={{ overflowX: "scroll", paddingBottom: "10px" }}
        className="container col-12"
      >
        <ButtonGroup
          sx={{ gap: "1rem" }}
          variant="outlined"
          aria-label="Basic button group"
        >
          {Isloading ? (
            <>
              {" "}
              <Box display={"flex"} flexDirection={"column"}>
                <Skeleton variant="rectangular" width={210} height={250} />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Skeleton variant="rectangular" width={210} height={250} />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Skeleton variant="rectangular" width={210} height={250} />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
            </>
          ) : (
            <>
              {" "}
              {stateProduct &&
                stateProduct.map((item, index) => (
                  <Card
                    sx={{
                      width: 205,

                      borderRadius: "10px",
                      position: "relative",
                      // display: "inline-block",
                    }}
                  >
                    <CardActionArea
                      sx={{ minHeight: "370px", height: "270px" }}
                    >
                      <CardMedia
                        component="img"
                        sx={{
                          padding: "0px 10px",
                          minHeight: "125px",
                          height: "125px",
                        }}
                        onClick={() => {
                          nav(`/pDetails/${item?._id}`);
                        }}
                        // minHeight={140}

                        image={item?.images[0]?.path}
                        alt="green iguana"
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          fontSize={Theme.designColors.SUBTITLESIZE}
                          component="div"
                        >
                          <TitleProduct> {item?.name}</TitleProduct>
                        </Typography>
                        <Box display={"flex"} gap={1} alignItems={"center"}>
                          <Typography variant="body2" color="text.secondary">
                            Giá:{" "}
                          </Typography>
                          <Typography
                            fontSize={Theme.designColors.SUBTITLESIZE2}
                            fontWeight={600}
                            color={Theme.designColors.PrimaryBrand1}
                          >
                            {" "}
                            {parseInt(item?.price_retail).toLocaleString(
                              "en-US"
                            )}{" "}
                            VND
                          </Typography>
                        </Box>
                        <Box display={"flex"} gap={1} alignItems={"center"}>
                          <Typography variant="body2" color="text.secondary">
                            Tồn kho:{" "}
                          </Typography>
                          <Typography
                            fontSize={Theme.designColors.SUBTITLESIZE2}
                            variant="body2"
                            color="text.secondary"
                          >
                            {" "}
                            {item?.store_company?.stock}
                          </Typography>
                        </Box>

                        <Box
                          className="row"
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <StyledRating
                            className="col-md-6"
                            name="customized-color"
                            defaultValue={4}
                            getLabelText={(value) =>
                              `${value} Heart${value !== 1 ? "s" : ""}`
                            }
                            precision={0.5}
                            icon={<FavoriteIcon fontSize="inherit" />}
                            emptyIcon={
                              <FavoriteBorderIcon fontSize="inherit" />
                            }
                          />
                          <Typography
                            className="col-md-6"
                            variant="body2"
                            color="text.secondary"
                          >
                            Đã bán: {item?.sold}
                          </Typography>
                        </Box>
                        <Box className="row">
                          <Box display={"flex"} className="col-md-12">
                            <LocationOnOutlinedIcon
                              fontSize="small"
                              sx={{ color: "gray" }}
                            ></LocationOnOutlinedIcon>{" "}
                            <Typography variant="body2" color="text.secondary">
                              {item?.store_company?.name}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                    {/* <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        backgroundColor: "rgba(204, 51, 102, 0.5)",

                        color: "white",
                        padding: "10px",
                        borderRadius: "0 0 10px 0",
                      }}
                    >
                      Khuyến mãi {item?.discount}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        backgroundColor: Theme.color.freeship,
                        color: "white",
                        padding: "10px",
                        borderRadius: "10px 0 0 0",
                      }}
                    >
                      FREE SHIP 100K
                    </div> */}
                  </Card>
                ))}
            </>
          )}
        </ButtonGroup>
      </Box>

      <Box mt={3}>
        <Stack spacing={2}>
          <Typography>Trang: {statePage}</Typography>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={stateCount}
            page={statePage}
            onChange={handleChange}
          />
        </Stack>
      </Box>

      {/* row 5 */}
      {/* <Box mt={3} display={"flex"} justifyContent={"space-between"}>
        <Typography
          color={Theme.color.title}
          fontSize={"24px"}
          width={"100%"}
          fontWeight={600}
        >
          Sự kiện
        </Typography>
      </Box>
      <Box mt={3} display={"flex"} gap={2} className="col-12 container">
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          className="col-md-6"
          gap={1}
        >
          <img src={IMGEVENT}></img>
          <Box gap={1} display={"flex"} className="col-md-12">
            <Box className="col-6">
              {" "}
              <img src={IMGEVENT}></img>
            </Box>
            <Box className="col-6">
              {" "}
              <img src={IMGEVENT}></img>
            </Box>
          </Box>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          className="col-md-6"
          gap={1}
        >
          <img width={"100%"} src={IMGEVENT}></img>
          <Box gap={1} display={"flex"} className="col-md-12">
            <Box className="col-6">
              {" "}
              <img src={IMGEVENT}></img>
            </Box>
            <Box className="col-6">
              {" "}
              <img src={IMGEVENT}></img>
            </Box>
          </Box>
        </Box>
      </Box> */}

      {/* row 6 */}
      <Box mt={3} display={"flex"} justifyContent={"space-between"}>
        <Typography
          color={Theme.color.title}
          fontSize={"24px"}
          width={"100%"}
          fontWeight={600}
        >
          Tìm hiểu thêm
        </Typography>
      </Box>
      <Box
        mt={3}
        display={"flex"}
        justifyContent={"space-between"}
        width={"100%"}
        className="row"
      >
        <Box mt={1} className="col-md-4">
          <MediaCard
            img={IMG1}
            Title={"LADY LEADER – THỎI SON KHƠI NGUỒN"}
            content={
              "Lady Leader là bộ sưu tập màu sắc của thương hiệu Mỹ phẩm xanh C’Choi vừa được ra mắt vào mùa hè ..."
            }
            ButtonContactText={"Xem thêm"}
          ></MediaCard>
        </Box>
        <Box mt={1} className="col-md-4">
          <MediaCard
            img={IMG2}
            Title={"ĐIỀU GÌ LÀM NÊN MỘT C’CHOI"}
            content={
              "Hiện nay, C’CHOI đã trở thành thương hiệu Mỹ Phẩm Thiên Nhiên – Mỹ phẩm Xanh được đông đảo ..."
            }
            ButtonContactText={"Xem thêm"}
          ></MediaCard>
        </Box>
        <Box mt={1} className="col-md-4">
          <MediaCard
            img={IMG3}
            Title={"Làm việc ở nhà đừng quên chống nắng"}
            content={
              "Quên trang điểm hay dưỡng một ngày có thể không sao, nhưng quên chống nắng là có sao đấy ..."
            }
            ButtonContactText={"Xem thêm"}
          ></MediaCard>
        </Box>
      </Box>
    </>
  );
};
export default Product;
