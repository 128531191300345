import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Theme } from "../../theme";
import ICONTOANQUOC from "../../assets/Icon Tikpii/Shopicons_Light_Location.svg";
import ICONDROPDOWN from "../../assets/Icon Tikpii/Huge-icon.svg";
import ICONORDER from "../../assets/Icon Tikpii/Shopicons_Light_Cart5.svg";
import LOGO from "../../assets/logo/logo1.png";
import { useMutation } from "@tanstack/react-query";
import { LOG_OUT } from "../../services/handleAccount/handleAccount";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  right: 0,
  top: 0,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    borderRadius: "10px",
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    backgroundColor: "white",
  },
}));

export default function Navbar({
  stateAccount,
  TriggerSetStateAccount,
  stateCart,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  let nav = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const MutationLogout = useMutation({
    mutationKey: ["log_out"],
    mutationFn: (body) => {
      return LOG_OUT(body);
    },
  });
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();

    MutationLogout.mutate(
      {},
      {
        onSuccess: (data) => {
          localStorage.clear();
          TriggerSetStateAccount();
        },
        onError: (err) => {
          // localStorage.clear();
        },
      }
    );
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={() => {
          setAnchorEl(null);
          handleMobileMenuClose();
        }}
      >
        <MenuItem onClick={handleMenuClose}>Đăng xuất</MenuItem>
      </Menu>
    </>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {stateAccount?.name ? (
        <Box>
          <MenuItem>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                nav("/cart");
              }}
            >
              <Badge
                badgeContent={stateCart && stateCart[0]?.purchase_items?.length}
                color="error"
              >
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
            <p>GIỎ HÀNG</p>
          </MenuItem>
          <MenuItem>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <p>ĐƠN HÀNG</p>
          </MenuItem>
          <MenuItem onClick={handleProfileMenuOpen}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <p>{stateAccount?.name}</p>
          </MenuItem>
        </Box>
      ) : (
        <Typography
          p={1}
          sx={{ "&:hover": { color: "blue" }, cursor: "pointer" }}
          onClick={() => {
            nav("/login");
          }}
        >
          <PersonIcon sx={{ marginRight: "1rem" }}></PersonIcon> Đăng nhập
        </Typography>
      )}
    </Menu>
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        background: `linear-gradient(to right, #F9F8E5, ${Theme.designColors.PrimaryBrand1})`,
      }}
    >
      <AppBar
        style={{
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
          color: Theme.designColors.Text,

          padding: "0px",
        }}
        className="container"
        position="static"
      >
        <Toolbar
          style={{
            justifyContent: "space-between",
            width: "100%",
            padding: "0px",
          }}
        >
          <img
            style={{ mixBlendMode: "multiply", cursor: "pointer" }}
            onClick={() => {
              nav("/");
            }}
            src={LOGO}
          ></img>

          <Search>
            <SearchIconWrapper
              style={{
                zIndex: 2,

                color: "white",
                borderRadius: "0px 0px 0px 20px",
                background: `linear-gradient(to bottom right, ${Theme.designColors.PrimaryBrand1} 50%, ${Theme.designColors.SecondaryBrand1}) 50%`,
              }}
            >
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Tìm kiếm sản phẩm.."
              inputProps={{ "aria-label": "Tìm kiếm sản phẩm.." }}
            />
          </Search>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            className="hide-in-mobile"
            gap={1}
          >
            <img width={25} height={25} src={ICONTOANQUOC}></img>
            <Typography fontWeight={700}>Toàn quốc</Typography>
            <img width={25} height={25} src={ICONDROPDOWN}></img>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={1}
            className="hide-in-mobile"
          >
            <img width={25} height={25} src={ICONORDER}></img>
            <Typography fontWeight={700}>Đơn hàng</Typography>
          </Box>
          <Box />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {stateAccount?.name ? (
              <>
                {" "}
                {/* <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <Badge badgeContent={4} color="error">
                    <MailIcon />
                  </Badge>
                </IconButton> */}
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    nav("/cart");
                  }}
                >
                  <Badge
                    badgeContent={
                      stateCart && stateCart[0]?.purchase_items?.length
                    }
                    color="error"
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="small"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle sx={{ marginRight: "1rem" }} />{" "}
                  {stateAccount?.name}
                </IconButton>
              </>
            ) : (
              <Typography
                sx={{ "&:hover": { color: "blue" }, cursor: "pointer" }}
                onClick={() => {
                  nav("/login");
                }}
              >
                <PersonIcon sx={{ marginRight: "1rem" }}></PersonIcon> Đăng nhập
              </Typography>
            )}
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
