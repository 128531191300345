import ProductPC from "./ProductPC";
import "./App.css";
import { GET_ALL_PRODUCT } from "../../services/handleProduct/handleProduct";
import { GET_ALL_PROVIDER } from "../../services/handleProvider/handleProvider";
import { GET_ALL_CATEGORY } from "../../services/handleCategory/handleCategories";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Box } from "@mui/material";
const Product = ({
  stateCategory,
  stateProvider,
  isloadingCategory,
  isloadingProvider,
}) => {
  const [stateProduct, setStateProduct] = useState([]);
  const [statePage, setStatePage] = useState(1);
  const [stateLimit, setStateLimit] = useState(15);
  const [stateCount, setStateCount] = useState(0);

  const DataAllProduct = useQuery({
    queryKey: ["GetAllProduct", statePage],
    queryFn: async () => {
      const result = await GET_ALL_PRODUCT({
        page: statePage,
        limit: stateLimit,
      });
      if (result?.data?.data) {
        setStateCount(result?.data?.data?.total_page);
        setStateProduct(result?.data?.data?.products);
      }
      return result;
    },
  });

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        className="container"
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
      >
        <ProductPC
          //Product

          stateProduct={stateProduct}
          Isloading={DataAllProduct.isFetching}
          statePage={statePage}
          setStatePage={setStatePage}
          stateLimit={stateLimit}
          setStateLimit={setStateLimit}
          stateCount={stateCount}
          //Provider
          isloadingCategory={isloadingCategory}
          isloadingProvider={isloadingProvider}
          stateProvider={stateProvider}
          stateCategory={stateCategory}
        ></ProductPC>
      </Box>
    </>
  );
};
export default Product;
