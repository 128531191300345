import Navbar from "../../component/Navbar/navbar";

import Footer from "../../component/Footer/footer";
import * as React from "react";
import { useParams, useLocation } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Images as images } from "./mockdata";
import "./App.css";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Link,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import Gallery from "./Components/Gallery";
import Description from "./Components/Description";
import MobileGallery from "./Components/MobileGallery";
import { mockdataProduct } from "../../data/data";
import { GET_PRODUCT } from "../../services/handleProduct/handleProduct";
import { useQuery } from "@tanstack/react-query";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { Theme } from "../../theme";
import { BreadcrumbsCustom } from "../../component/component";
const ProductDetails = ({ sendDataToBase, TriggerRefreshCart }) => {
  const [stateProduct, setStateproduct] = React.useState({});
  const { productid } = useParams();
  const [quant, setQuant] = React.useState(0);
  const [orderedQuant, setOrderedQuant] = React.useState(0);
  let nav = useNavigate();
  let navCustomBread = [
    {
      nav: "/",
      name: "Trang chủ",
    },
    {
      nav: "",
      name: "Chi tiết sản phẩm",
    },
    {
      nav: "",
      name: stateProduct?.name,
    },
  ];
  const DataGetProduct = useQuery({
    queryKey: ["GetProduct"],
    queryFn: async () => {
      const result = await GET_PRODUCT({
        productID: productid,
      });
      if (result?.data?.data) {
        setStateproduct(result?.data?.data);
      }
      return result; // Ensure the result is returned
    },
  });

  const addQuant = () => {
    setQuant(quant + 1);
  };

  const removeQuant = () => {
    setQuant(quant - 1);
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");

    const handleMediaChange = (e) => {
      if (e.matches) {
        console.log("Màn hình có kích thước 768px hoặc nhỏ hơn");
      }
    };

    // Kiểm tra ngay khi component được mount
    handleMediaChange(mediaQuery);

    // Đăng ký lắng nghe sự thay đổi kích thước màn hình
    mediaQuery.addEventListener("change", handleMediaChange);

    // Cleanup khi component unmount
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <>
      <Box className="row">
        <Container
          style={{
            backgroundColor: "white",
            padding: "0px",
            maxWidth: "1500px",
          }}
          component="section"
          maxWidth={"lg"}
        >
          {DataGetProduct?.isFetching ? (
            "Đang tải dữ liệu"
          ) : (
            <>
              {/* //DESKTOP */}
              <Box>
                {stateProduct?.images ? (
                  <section
                    style={{
                      backgroundColor: "white",
                      // gap: "100px",
                      margin: "60px auto",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    className="core row"
                  >
                    <Box
                      className="col-md-5"
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <BreadcrumbsCustom
                        ListBreed={navCustomBread}
                      ></BreadcrumbsCustom>
                      <Gallery
                        IMAGES={stateProduct?.images || []}
                        THUMBS={stateProduct?.images || []}
                      />
                      <MobileGallery
                        IMAGES={stateProduct?.images || []}
                        THUMBS={stateProduct?.images || []}
                      />
                    </Box>
                    <Box className="col-md-5">
                      <Description
                        onQuant={quant}
                        onAdd={addQuant}
                        onRemove={removeQuant}
                        onSetOrderedQuant={setOrderedQuant}
                        stateProduct={stateProduct}
                        TriggerRefreshCart={TriggerRefreshCart}
                      />
                    </Box>
                  </section>
                ) : (
                  "Không tìm thấy sản phẩm"
                )}
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};
export default ProductDetails;
